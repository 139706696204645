<template>
  Add User
  <form @submit.prevent="addUser">
    <label>
      id
      <input type="number" class="input mb-4" v-model="id" />
    </label>
    <label>
      email
      <input type="email" class="input mb-4" v-model="email" />
    </label>
    <label>
      token
      <input type="text" class="input mb-4" v-model="token" />
    </label>
    <label>
      name
      <input type="text" class="input mb-4" v-model="firstName" />
    </label>
    <label>
      last name
      <input type="text" class="input mb-4" v-model="lastName" />
    </label>

    <button>add</button>
  </form>
  ---
</template>

<script lang="ts">
import api from '@/api'
import { defineComponent, ref } from 'vue'

export default defineComponent({
  setup() {
    const id = ref('')
    const token = ref('')
    const firstName = ref('')
    const lastName = ref('')
    const email = ref('@p-programisci.pl')

    async function addUser() {
      api.users.create({
        id: +id.value,
        accessToken: token.value,
        firstName: firstName.value,
        lastName: lastName.value,
        email: email.value,
        picture:
          'https://lh3.googleusercontent.com/a/AATXAJyEUYiDPk1swAe_dWZ0DzdVVbTlM36zQDTq8no-=s96-c'
      })
    }

    return {
      addUser,
      email,
      id,
      token,
      firstName,
      lastName
    }
  }
})
</script>
